<template>
    <div class="gap-x-12 md:flex">
        <div class="md:w-2/5 xl:w-1/4 2xl:w-1/5">
            <div class="hidden md:block">
                <slot name="tab-heading"></slot>
                <DesktopTabs
                    v-model="currentSlug"
                    :tabs="tabs"
                    :slug="currentSlug"
                />
                <slot name="cta"></slot>
            </div>
            <MobileTabs
                v-model="currentSlug"
                class="md:hidden"
                :tabs="tabs"
                :slug="currentSlug"
            />
        </div>
        <div class="mt-12 md:mt-0 md:w-3/5 xl:w-3/4 2xl:w-4/5">
            <slot name="content"></slot>
        </div>
        <div class="md:hidden">
            <slot name="cta"></slot>
        </div>
    </div>
</template>

<script setup lang="ts">
import TabContentViewTabType from '~/types/TabContentViewTabType';
import { ref, watch } from 'vue';
import DesktopTabs from '~/components/page-building/components/tab-content-view/DesktopTabs.vue';
import { slugify } from '~/utils/helpers';
import MobileTabs from '~/components/page-building/components/tab-content-view/MobileTabs.vue';
const emit = defineEmits(['update:model-value']);

const props = defineProps<{
    tabHeading?: string;
    tabs: TabContentViewTabType[];
    slug: string | null;
    urlParam: string;
}>();

const currentSlug = ref<string | null>(props.slug);

const setUrlParams = () => {
    const url = new URL(window.location.href);
    url.searchParams.set(props.urlParam, slugify(currentSlug.value));
    window.history.replaceState(null, null, url);
};

watch(
    () => currentSlug.value,
    () => {
        emit('update:model-value', currentSlug.value);
        //setUrlParams();
    },
);
</script>
